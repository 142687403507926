'use client';

import React, { useEffect, useRef } from 'react';
import lottie from 'lottie-web';

interface LottieAnimationProps {
	animation: any;
}
const LottieAnimation: React.FC<LottieAnimationProps> = (props) => {
	const animationContainer = useRef(null);

	useEffect(() => {
		if (animationContainer.current) {
			lottie.loadAnimation({
				container: animationContainer.current,
				renderer: 'svg',
				loop: true,
				autoplay: true,
				animationData: props.animation,
			});
		}
	}, []);

	return <div className='lottieAnimationContainer w-full' ref={animationContainer}></div>;
};

export default LottieAnimation;
