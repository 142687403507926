import(/* webpackMode: "eager" */ "/app-build/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app-build/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/app-build/public/images/icons/grayMarker.svg");
;
import(/* webpackMode: "eager" */ "/app-build/public/images/placeholder.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app-build/src/layouts/components/LottieAnimation.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app-build/src/layouts/partials/clubPage/projectCard/projectCardProgress.tsx");
;
import(/* webpackMode: "eager" */ "/app-build/src/layouts/partials/clubPage/quote/index.scss");
;
import(/* webpackMode: "eager" */ "/app-build/src/layouts/partials/clubPage/projectCard/index.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app-build/src/layouts/partials/common/faqItems/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app-build/src/layouts/partials/common/newslettersForm/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app-build/src/layouts/partials/common/sections/partnersSection/index.tsx");
;
import(/* webpackMode: "eager" */ "/app-build/src/layouts/partials/common/statusIndicator/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app-build/src/layouts/partials/mecenatPage/slideMecenat/index.tsx");
